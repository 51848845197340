import React from 'react'
import * as styles from './section-grid.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocalPath } from '../../../hooks';
import { Link } from 'gatsby';

import ButtonPrimary from '../../Shared/ButtonPrimary/ButtonPrimary';
import ArticleCard from '../../Shared/ArticleCard/ArticleCard';

const SectionGrid = ({
    data
}) => {
    const {
        title,
        containerSize,
        gridItems,
        numberOfGridItems,
        columnGap,
        rowGap,
        justifyContent,
        gridWidth,
        triangle,
        titleAlignment,
        titleInsideInTile,
        notEqual,
        extraClass
    } = data

    return (
        <div className={`${triangle ? 'triangle' : ''} ${styles[extraClass]}`}>
            <div className={`container container-${containerSize} ${styles[containerSize]} ${styles[(titleAlignment || 'title_center')]}`}>
                {
                    title
                        ? <h3 className={styles.title}>{title}</h3>
                        : null
                }
                <div
                    className={styles.grid}
                    style={{
                        gridTemplateColumns: `${notEqual ? `auto auto auto auto` : `repeat(${numberOfGridItems}, 1fr)`}`,
                        columnGap: `${columnGap}px`,
                        rowGap: `${rowGap}px`,
                        justifyContent: `${justifyContent ? justifyContent : ''}`
                    }}>
                    {
                        gridItems.map(({ __typename, title, image, slug, target, description, buttonLink, buttonColor }, index) => {
                            switch (__typename) {
                                case "ContentfulComponentLink":
                                    return (slug || target) ? (
                                        // <Link
                                        //     to={useLocalPath(slug || target.slug)}
                                        //     key={index}
                                        //     style={{ width: `${gridWidth ? gridWidth : ''}` }}
                                        //     className={styles.itemContainer}
                                        // >
                                        //     <GatsbyImage
                                        //         image={image.gatsbyImageData}
                                        //         alt={title || image.title}
                                        //     />
                                        //     <div className={`${styles.contentContainer} ${titleInsideInTile ? styles.inside : ''}`}>
                                        //         {
                                        //             title.split('/n').map((phrase, index) => {
                                        //                 return index == 0 ? (
                                        //                     <p key={index} className={styles.gridTitle}>{phrase}</p>
                                        //                 ) : (
                                        //                     <p key={index} className={styles.gridDesc}>{phrase}</p>
                                        //                 )
                                        //             })
                                        //         }
                                        //     </div>
                                        // </Link>
                                        <ArticleCard slug={slug || target.slug} thumbnailImage={image} title={title} key={index} />
                                    ) : (
                                        <div key={index} style={{ width: `${gridWidth ? gridWidth : ''}` }} className={styles.itemContainer}>
                                            <GatsbyImage
                                                image={image.gatsbyImageData}
                                                alt={title || image.title}
                                            />
                                            <div className={`${styles.contentContainer} ${titleInsideInTile ? styles.inside : ''}`}>
                                                {
                                                    title.split('/n').map((phrase, index) => {
                                                        return index == 0 ? (
                                                            <p key={index} className={styles.gridTitle}>{phrase}</p>
                                                        ) : (
                                                            <p key={index} className={styles.gridDesc}>{phrase}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                case "ContentfulComponentItem":
                                    return (
                                        <div className={styles.itemGrid} key={index}>
                                            <GatsbyImage image={image?.gatsbyImageData} alt={title || image?.title} />
                                            <div className={styles.itemContent}>
                                                <p className={styles.itemTitle}>
                                                    {
                                                        title?.split('/n').map((phrase, index) => {
                                                            return index == 0 ? (
                                                                <span key={index} style={{ display: 'block' }}>{phrase}</span>
                                                            ) : (
                                                                <span key={index} style={{ display: 'block' }}>{phrase}</span>
                                                            )
                                                        })
                                                    }
                                                </p>
                                                <p className={styles.itemDesc}>{description?.description}</p>
                                            </div>
                                            <div className={styles.empty}>&nbsp;</div>
                                            {
                                                buttonLink &&
                                                <ButtonPrimary
                                                    link={buttonLink?.target?.slug}
                                                    text={buttonLink?.title}
                                                    extraClass={`${styles.itemButton} ${buttonColor ? styles.coloredButton : ''}`}
                                                />
                                            }
                                        </div>
                                    )
                                case "ContentfulComponentCard":
                                    return (
                                        <div className={styles.cardItem} key={index}>
                                            <div className={styles.cardTitleContainer}>
                                                <p>{title}</p>
                                                <GatsbyImage
                                                    image={image.gatsbyImageData}
                                                    alt={title || image.title}
                                                    className={styles.cardImage}
                                                />
                                            </div>
                                            <p className={styles.cardDesc}>{description?.description}</p>
                                        </div>
                                    )
                            }
                        })
                    }
                </div>
            </div>
        </div >
    )
}

export default SectionGrid